
import { defineComponent, onMounted, PropType, ref } from 'vue';
import ModalButton from '@/components/util/ModalButton.vue';
import Modal from '@/components/util/Modal.vue';
import { mdiChevronRight } from '@mdi/js';
import { useStore } from 'vuex';

type TrainerRecord = {
  id: number;
  name: string;
  ort: string;
  schwerpunkt: string;
  referenz: string;
  feedback: string;
  tagsatz: string;
  tagsatzcomment: string;
  url: string;
};

export default defineComponent({
  components: {
    ModalButton,
    Modal,
  },
  emits: ['changed'],
  props: {
    trainer: { type: Object as PropType<TrainerRecord>, required: true },
    edit: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    //const edit = ref(false);
    const store = useStore();
    const id = ref(0);
    const name = ref('');
    const ort = ref('');
    const schwerpunkt = ref('');
    const referenz = ref('');
    const feedback = ref('');
    const tagsatz = ref('');
    const tagsatzcomment = ref('');
    const url = ref('');

    onMounted(() => {
      if (props.trainer.id != 0) {
        id.value = props.trainer.id;
        name.value = props.trainer.name;
        ort.value = props.trainer.ort;
        schwerpunkt.value = props.trainer.schwerpunkt;
        referenz.value = props.trainer.referenz;
        feedback.value = props.trainer.feedback;
        tagsatz.value = props.trainer.tagsatz;
        tagsatzcomment.value = props.trainer.tagsatzcomment;
        url.value = props.trainer.url;
      }
    });

    const save = () => {
      const data = {
        name: name.value,
        ort: ort.value,
        schwerpunkt: schwerpunkt.value,
        referenz: referenz.value,
        feedback: feedback.value,
        tagsatz: tagsatz.value,
        tagsatzcomment: tagsatzcomment.value,
        url: url.value,
      };
      if (id.value != 0) {
        store.dispatch('updateTrainer', { id: id.value, data }).then(() => {
          emit('changed');
        });
      } else {
        store.dispatch('addtrainer', data).then(() => {
          emit('changed');
        });
      }
      // edit.value = false;
    };

    const del = () => {
      store.dispatch('deleteTrainer', id.value).then(() => {
        emit('changed');
      });
    };

    // const onEdit = () => {
    //   edit.value = true;
    // };

    //     fetchTrainers(context: any) {

    // deleteTrainer(context: any, id: number) {

    // updateTrainer(context: any, payload: { id: number; data: TrainerRequestBody }) {

    // addtrainer(context: any, payload: TrainerRequestBody) {

    return {
      mdiChevronRight,
      save,
      del,
      //   onEdit,
      id,
      name,
      ort,
      schwerpunkt,
      referenz,
      feedback,
      tagsatz,
      tagsatzcomment,
      url,
      // edit,
    };
  },
});
