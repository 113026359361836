
import { useMsalAuthentication } from '@/composition-api/useMsalAuthentication';
import store from '@/store';
import { loginRequest } from '@/store/auth/activedirectory';
import { InteractionType } from '@azure/msal-browser';
import jwtDecode from 'jwt-decode';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { z } from 'zod';
import { mdiChevronRight } from '@mdi/js';

export default defineComponent({
  setup() {
    const router = useRouter();

    const msalauth = useMsalAuthentication(InteractionType.Redirect, loginRequest);

    const acceptInvite = () => {
      if (!msalauth.result.value) return;
      const decoded = z
        .object({
          oid: z.string(),
        })
        .safeParse(jwtDecode(msalauth.result.value.accessToken));

      if (decoded.success) {
        store
          .dispatch('boardUser', { key: router.currentRoute.value.params.key, oid: decoded.data.oid })
          .then((res) => {
            store.dispatch('login', { token: msalauth.result.value?.accessToken }).then(() => {
              store.dispatch('addNotification', { type: 'sucess', message: 'Willkommen!' });
              router.push({ name: 'Index' });
            });
          })
          .catch(() => {
            store.dispatch('addNotification', { type: 'error', message: 'Etwas ist schiefgelaufen.' });
          });
      }
    };

    return {
      mdiChevronRight,
      acceptInvite,
      params: router.currentRoute.value.params,
    };
  },
});
