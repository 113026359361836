
import { computed, defineComponent, onMounted, ref } from 'vue';
import { mdiChevronUp, mdiChevronRight, mdiPlus } from '@mdi/js';

import { useStore } from 'vuex';
import { APIResult } from '@/store/api';
import TrainerItem from '@/components/TrainerItem.vue';

type TrainerRecord = {
  id: number;
  name: string;
  ort: string;
  schwerpunkt: string;
  referenz: string;
  feedback: string;
  tagsatz: string;
  tagsatzcomment: string;
  url: string;
};

export default defineComponent({
  components: {
    TrainerItem,
  },
  setup() {
    const store = useStore();
    const trainers = ref<Array<TrainerRecord>>([]);

    onMounted(() => {
      store.dispatch('fetchTrainers').then((res: APIResult) => {
        trainers.value = res.data.message;
      });
    });

    const onChanged = () => {
      store.dispatch('fetchTrainers').then((res: APIResult) => {
        trainers.value = res.data.message;
      });
    };

    const filteredtrainers = computed(() => {
      return trainers.value.filter(
        (item) => (ort.value == '' || item.ort == ort.value) && (focus.value == '' || item.schwerpunkt.includes(focus.value))
      );
    });

    const focusoptions = ['Coaching', 'Sprachtraining', 'Consulting', 'Flipchart', 'Management', 'Führung', 'Training', 'Rethorik'];
    const focus = ref('');
    const setFocus = (value: string) => {
      focus.value = value;
    };

    const ort = ref('');
    const setOrt = (value: string) => {
      ort.value = value;
    };

    const edit = ref(false);
    const onEdit = () => {
      edit.value = true;
    };
    const onEditStop = () => {
      edit.value = false;
    };

    const newItem = () => {
      trainers.value.push({
        id: 0,
        name: '',
        ort: '',
        schwerpunkt: '',
        referenz: '',
        feedback: '',
        tagsatz: '',
        tagsatzcomment: '',
        url: '',
      });
    };

    return {
      mdiChevronUp,
      mdiChevronRight,
      mdiPlus,
      filteredtrainers,
      focusoptions,
      setOrt,
      setFocus,
      ort,
      focus,
      onChanged,
      onEdit,
      onEditStop,
      newItem,
      edit,
    };
  },
});
